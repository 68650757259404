import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import {
  DowanloadDeleteServProviders,
  UndeleteSPAccount,
  getDeletedServiceProvider,
} from "../Redux/Actions/adminAction";
import Search from "../Components/Layout/Search";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";

export default function DeletedServicePRovider() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [Pagi, setpagi] = useState("");
  const [deletedProviderDetails, setDeletedProviderDetails] = useState([]);
  const [show, setshow] = useState(false);
  const [ID, setID] = useState("");

  useEffect(() => {
    dispatch(
      getDeletedServiceProvider({ search: search, page: page, limit: limit })
    ).then((res) => {
      console.log(res);
      if (res?.payload?.data?.status_code === 200) {
        setDeletedProviderDetails(res?.payload?.data?.data?.user);
        setpagi(res?.payload?.data?.data);
      }
    });
  }, [search, page, limit, flag]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadDeleteServProviders());
      console.log(response);

      const allData = response?.payload?.data?.user;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handleprofileAction = () => {
    dispatch(UndeleteSPAccount({ _id: ID })).then((res) => {
      if (res?.payload?.success) {
        toast.success(res?.payload?.message);
        setflag(!flag);
        setshow(false);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const handlenavigate = (id) => {
    navigate(`/service-provider-profile/${id}`);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2> Deleted Service Providers</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  {/* <Form.Control type="search" placeholder="Keyword Search.." /> */}
                  <Search setsearch={setsearch} />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                {/* <Form.Select
                
                >
                  
                </Form.Select> */}
              </Col>
              <Col
                xxl={7}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/CustomerManagement" className="active-tab">
                  All
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (page - 1) * limit} -{" "}
                {Pagi?.totalUser + (page - 1) * limit} of {Pagi?.totalUser}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Profile Name </th>
                <th>Phone Number </th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {deletedProviderDetails.map((user, index) => {
                const serialNumber = (page - 1) * limit + index + 1;

                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handlenavigate(user?._id)}
                      >
                        <b> {user?.full_name || "N/A"}</b>
                      </p>
                    </td>

                    <td>
                      {user?.country_code} {user?.phone_number || "N/A"}
                    </td>
                    <td>{user?.email || "N/A"}</td>

                    <td>
                      <div className="">
                        <Button
                          onClick={() => {
                            setshow(true);
                            setID(user?._id);
                          }}
                          className="account-btn theme-colr-btn"
                        >
                          Undo Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalstuff={Pagi?.totalPage}
        limit={limit}
        setpages={setpage}
        search={search}
      />

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Undo Delete this User?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleprofileAction}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
