import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ChageStatusOrderDetails,
  DelecteAccount,
  changeStatus,
  changeVerification,
  getOrderDetails,
  getUserProfile,
} from "../Redux/Actions/adminAction";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";

export default function OrderDetails() {
  const [orderDetail, setOrderdetails] = useState({});
  const [show, setshow] = useState(false);
  const [name, setName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [Value, setvalue] = useState("");

  const [flag, setflag] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getOrderDetails({ id: id }));
      console.log(data);
      if (data?.payload?.data?.success === true) {
        setOrderdetails(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  const handleShowModal = (action) => {
    setActionType(action);
    setShowModal(true);
  };

  const handleConfirm = (status) => {
    dispatch(ChageStatusOrderDetails({ booking_id: id, status }))
      .then((res) => {
        if (res?.payload?.success === true) {
          toast.success(res?.payload?.message);
          setShowModal(false);
          navigate('/OrderManagement')
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Order Details</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/OrderManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main mb-4">
                  <Row>
                    <Col lg={12}>
                      <div className=" px-3 py-4 h-100">
                        <div className="property-main mt-0">
                          <h2 className="property">ORDER INFO</h2>
                          <div className="customer-form-new border-none">
                            <Row>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Customer Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Customer Name"
                                    value={
                                      orderDetail?.bookBy?.full_name || "N/A"
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Service Provider</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Service Provider"
                                    value={
                                      orderDetail?.serviceProvider?.full_name ||
                                      "N/A"
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Service Category</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Service Category"
                                    value={
                                      orderDetail?.serviceCategory
                                        ?.service_category_name || "N/A"
                                    }
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Service Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Service name"
                                    value={
                                      orderDetail?.serviceSubCategory
                                        ?.serviceSubCategoryName || "N/A"
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Date</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Date"
                                    value={orderDetail?.date || "N/A"}
                                  />
                                </Form.Group>
                              </Col>
                              {orderDetail?.slotTime?.map((res) => (
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Time slot</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Time Slot"
                                      value={res}
                                    />
                                  </Form.Group>
                                </Col>
                              ))}
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="dummy"
                                    value={orderDetail?.address || "N/A"}
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                lg={12}
                                className="d-flex justify-content-end mt-3"
                              >
                                {/* <button type="button" className="add-btn">
                                  Save
                                </button> */}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12} className="mb-4">
                    <div className="user-profile-main px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Service status</h2>
                        {orderDetail?.status === 1 ? (
                          <div className="over-view-btn reject-btn">
                            <button
                              onClick={() => handleShowModal("Accept")}
                              value="2"
                            >
                              Accept
                            </button>
                            <button
                              onClick={() => handleShowModal("Reject")}
                              value="5"
                            >
                              Reject
                            </button>
                          </div>
                        ) : orderDetail?.status === 2 ? (
                          "Accepted"
                        ) : orderDetail?.status === 3 ? (
                          "Canceled"
                        ) : (
                          "Completed"
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to {actionType} this order?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button  className="theme-btn" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            className="theme-btn"
            onClick={() => handleConfirm(actionType === "Accept" ? 2 : 5)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
