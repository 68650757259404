import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import Pagination from "../Components/Layout/Pagination";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  dowanloadsupportlist,
  getSupportList,
  updatesupportstatus,
} from "../Redux/Actions/adminAction";
import * as XLSX from "xlsx";

export default function Support() {
  const dispatch = useDispatch();
  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [pageType, setPageType] = useState("");
  const [pagi, setPagi] = useState("");
  const [supportdata, setSupportdata] = useState([]);
  const [showdel, setshowdel] = useState(false);
  const [Status, setstatus] = useState("");
  const [show, setshow] = useState(false);
  const [ID, setID] = useState("");
  const [value, setvalue] = useState("");


  useEffect(() => {
    dispatch(getSupportList({ page, search, limit, Status }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.success === true) {
          setSupportdata(res?.payload?.data?.data);
          setPagi(res?.payload?.data?.pagination);
        }
      })
      .catch((err) => {
        console.log(err, "Error Occur");
      });
  }, [page, search, limit, flag, Status]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(dowanloadsupportlist());
      console.log(response);

      const allData = response?.payload?.data;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handlestaus = () => {
    dispatch(updatesupportstatus({ support_id: ID, status: value }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.success === true) {
          toast.success(res?.payload?.data?.message);
          setflag(!flag);
          setshow(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const startIndex = 1 + (page - 1) * limit;
  const endIndex = Math.min(page * limit, pagi?.totalItems);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Support</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Search.." />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setstatus(e.target.value)}
                >
                  <option>Status</option>
                  <option value="">All</option>

                  <option value="1">Open</option>
                  <option value="2">Closed</option>
                </Form.Select>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Support" className="active-tab">
                  ALL
                </Link>
              </li>
              {/* <li>
                <Link to="#">Open 18</Link>
              </li>
              <li>
                <Link to="#">Closed 160</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {startIndex} - {endIndex} of {pagi?.totalItems} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e?.target?.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Sender Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Description</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {supportdata?.map((res, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      {res?.createdAt
                        ? new Date(res.createdAt).toLocaleDateString("en-GB")
                        : "N/A"}
                    </td>
                    <td>
                      <b>{res?.user_id?.full_name || "N/A"}</b>
                    </td>

                    <td>{res?.user_id?.email || "N/A"}</td>
                    <td>
                      {res?.user_id?.country_code
                        ? `${res?.user_id?.country_code} ${res?.user_id?.phone_number} `
                        : "N/A"}
                    </td>
                    <td>{res?.message || "N/A"}</td>
                    <td>{res?.status === 1 || res?.status === 0 ? "Open" : res?.status === 2 ? "Close" :""}</td>

                    <td>
                      <div className="select-box">
                        <Form.Select
                          aria-label="Default select example"
                          value={res?.status}
                          disabled={res?.status == "2" ? true : false}
                          onChange={(e) => {
                            setshow(true);
                            setID(res?._id);
                            setvalue(e.target.value);
                          }}
                        >
                          <option value="1">Open</option>
                          <option value="2">Close</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Close this Ticket?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handlestaus}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Pagination totalstuff={pagi.totalItems / limit} setpages={setpage} />
    </Layout>
  );
}
