import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";

import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CreateCommunit } from "../Redux/Actions/adminAction";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  date: Yup.date().required("Required"),
  description: Yup.string().required("Required"),
  location: Yup.string().required("Required"),
  booking: Yup.string().required("Required"),
  photos: Yup.mixed().required("Required"),
});

export default function CreateEvent() {
  const [file, setFile] = useState();
  const [images, setImages] = useState([]);
  const [address, setAddress] = useState({
    label: "",
    lat: "",
    lng: "",
    value: {
      description: "",
      place_id: "",
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    date: "",
    description: "",
    location: "",
    booking: "",
    photos: null,
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("eventName", values.name);
      formData.append("eventAddress", address?.label);
      formData.append("eventDate", values.date);
      formData.append("description", values.description);
      formData.append("locationLink", values.location);
      formData.append("bookingLink", values.booking);
      formData.append("lat", address?.lat);
      formData.append("long", address?.lng);
      
      formData.append("place_id", address?.value?.place_id);

      values.photos.forEach((image) => {
        formData.append("photos", image);
      });

      const data = await dispatch(CreateCommunit(formData)).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.data?.message);
          navigate("/CommunityManagement");
          resetForm();
          setImages([]);
        }
      });
    } catch (error) {
      console.error("Error creating event:", error);
    }

    setSubmitting(false);
  };



  const handlePlaceSelect = (place) => {
    console.log("place", place);
    if (!place) {
      setAddress(null);
      return;
    }

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ placeId: place?.value?.place_id }, (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0]?.geometry?.location;
        const newLocation = {
          lat: location?.lat(),
          lng: location?.lng(),
        };
        setAddress({
          label: place.label,
          lat: newLocation?.lat,
          lng: newLocation?.lng,
          value: {
            description: place.value.description,
            place_id: place.value.place_id,
          },
        });
      } else {
        console.error(
          "Geocode was not successful for the following reason: " + status
        );
        toast.error("Failed to fetch coordinates");
      }
    });
  };

  console.log("addresss", address);

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="right-top">
              <div className="heading-top-inner">
                <div>
                  <h2>Create Event</h2>
                </div>
              </div>
              <hr />
            </div>

            <Container fluid>
              <div className="product-cmn-tab mb-4 pe-4">
                <Row>
                  <Col lg={4}>
                    <div className="product-tab-left">
                      <Link to="/CommunityManagement">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="42"
                          viewBox="0 0 40 42"
                          fill="none"
                        >
                          <path
                            d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                            fill="#40413A"
                          />
                        </svg>{" "}
                        Back to previous page
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={8}
                    md={8}
                    className="d-flex justify-content-end align-items-center p-0"
                  ></Col>
                </Row>
              </div>
            </Container>

            <Container fluid className="height-set px-4">
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={8}>
                      <div className="user-profile-main mb-4">
                        <Row>
                          <Col lg={12}>
                            <div className="px-3 py-4 h-100">
                              <div className="property-main mt-0">
                                <h2 className="property">Event Details</h2>
                                <div className="customer-form-new border-none">
                                  <Row>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Event Name</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Name"
                                          name="name"
                                          value={values?.name}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name="name"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Address</Form.Label>
                                        <GooglePlacesAutocomplete
                                          selectProps={{
                                            value: address,
                                            onChange: (place) =>
                                              handlePlaceSelect(place),
                                            isClearable: true,
                                            placeholder:
                                              "Search for a location...",
                                          }}
                                        />
                                        <ErrorMessage
                                          name="address"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                          type="date"
                                          placeholder="Date"
                                          name="date"
                                          value={values?.date}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name="date"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          placeholder="Description"
                                          name="description"
                                          value={values?.description}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name="description"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Location link</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Location"
                                          name="location"
                                          onChange={handleChange}
                                          value={values?.location}
                                        />
                                        <ErrorMessage
                                          name="location"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Booking link</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Booking"
                                          name="booking"
                                          onChange={handleChange}
                                          value={values?.booking}
                                        />
                                        <ErrorMessage
                                          name="booking"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col
                                      lg={12}
                                      className="d-flex justify-content-end mt-3"
                                    >
                                      <button type="submit" className="add-btn">
                                        Create
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <Row>
                        <Col lg={12}>
                          <div className="user-profile-main px-3 py-4 h-100">
                            <div className="property-main mt-0">
                              <h2 className="property mb-2">Photos</h2>
                              <div className="images-gallery">
                                <Row>
                                  <Col lg={6}>
                                    <div className="image-box-item">
                                      <label>Product Images</label>
                                      <div className="user_detail_img_inner w-100">
                                        <img
                                        // src={require()}
                                        />
                                      
                                        <div className="main-img-contain">
                                          {images.map((image, index) => (
                                            <div
                                              key={index}
                                              className="image-container"
                                            >
                                              <img
                                                src={URL.createObjectURL(image)}
                                                alt={`Preview ${index}`}
                                              />
                                            </div>
                                          ))}
                                        </div>
                                        <br/>
                                        <input
                                          className="user_detail_upload"
                                          id="formFileSm"
                                          type="file"
                                          name="photos"
                                          onChange={(e) => {
                                            const files = e.target.files;
                                            const selectedImages = [];
                                            Array.from(files).forEach(
                                              (file) => {
                                                selectedImages.push(file);
                                              }
                                            );
                                            if (selectedImages.length <= 2) {
                                              setImages(selectedImages);
                                              setFieldValue(
                                                "photos",
                                                selectedImages
                                              );
                                            } else {
                                              toast.error(
                                                "Max Two images upload only"
                                              );
                                              setImages([]);
                                              setFieldValue("photos", []);
                                            }
                                          }}
                                          accept="image/*"
                                          multiple
                                        />
                                        <ErrorMessage
                                          name="photos"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </Layout>
  );
}
