import { toast } from "react-toastify";
import Adminapi from "../../services/AdminApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

// // Logout
// export const LogOut = createAsyncThunk("LogOut", async () => {
//   console.log("first");
//   const { data } = await Adminapi.post("/logout");

//   if (data?.success) {
//     toast.success(data?.message);
//     sessionStorage.clear();
//     window.location.href = "/";
//   } else {
//   }
// });

// change password

export const Changepassword = createAsyncThunk(
  "Changepassword",
  async (details) => {
    const data = await Adminapi.post("/changeAdminPassword", details);
    console.log(data);
    return data;
  }
);

// Forgot Password

export const ForgotPass = createAsyncThunk(
  "ForgotPassword",
  async (details) => {
    const data = await Adminapi.post("/forgotPassword", details);
    return data;
  }
);

//  OtpVerify

export const OtpVerify = createAsyncThunk("OtpVerify", async (details) => {
  const data = await Adminapi.post("/verifyOtp", details);
  return data;
});

// resetpassword

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (details) => {
    const data = await Adminapi.post("/resetPassword", details);
    return data;
  }
);

//   user listing

export const userListing = createAsyncThunk("userListing", async (details) => {
  const { data } = await Adminapi.get(
    `/userManagement?page=${details?.page}&limit=${details?.limit}&name=${details?.search}&type=${details?.type}`
  );

  return data;
});

//   account verification

export const accountVerification = createAsyncThunk(
  "accountVerification",
  async (details) => {
    const { data } = await Adminapi.get(
      `/getUnVerifiedUserList?page=${details?.page}&limit=${details?.limit}&search=${details?.search}&type=${details?.type}`
    );

    return data;
  }
);

// download  account verification

export const downloadaccountVerification = createAsyncThunk(
  "downloadaccountVerification",
  async () => {
    const { data } = await Adminapi.get(`/getUnVerifiedUserList`);

    return data;
  }
);

//   account verification status

export const changeVerification = createAsyncThunk(
  "verifyUser",
  async (details) => {
    const { data } = await Adminapi.post(`/verifyUser`, details);

    return data;
  }
);

//  change profile status and suspend Account

export const changeStatus = createAsyncThunk(
  "changeStatus",
  async (details) => {
    const { data } = await Adminapi.post(
      `/activateDeactivateCustomer`,
      details
    );

    return data;
  }
);

//   customer data

export const getCustomer = createAsyncThunk("getCustomer", async (details) => {
  const { data } = await Adminapi.get(
    `/getCustomers?page=${details?.page}&limit=${details?.limit}&search=${details?.search}&type=${details?.type}`
  );

  return data;
});

// Get Customer profile

export const getCustomerProfile = createAsyncThunk(
  "getCustomerProfile",
  async (details) => {
    const { data } = await Adminapi.get(`/getCustomerProfile?id=${details}`);
    return data;
  }
);

//   service data

export const getService = createAsyncThunk("getService", async (details) => {
  const { data } = await Adminapi.get(
    `/getServiceUsers?page=${details?.page}&limit=${details?.limit}&search=${details?.search}&type=${details?.type}`
  );

  return data;
});

// Dowanloal Service Provider
export const DowanloadServiceProvider = createAsyncThunk(
  "DowanloadServiceProvider",
  async (details) => {
    const { data } = await Adminapi.get(`/getServiceUsers`);

    return data;
  }
);

//  download customer data

export const downloadCustomer = createAsyncThunk(
  "downloadCustomer",
  async () => {
    const { data } = await Adminapi.get(`/getCustomers`);

    return data;
  }
);

// Get user profile

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (details) => {
    const { data } = await Adminapi.get(
      `/userProfile?userId=${details.userId}`
    );
    return data;
  }
);

// Delete Account

export const DelecteAccount = createAsyncThunk(
  "DelecteAccount",
  async (details) => {
    const data = await Adminapi.post("/deleteCustomer", details);
    return data;
  }
);

// user list download

export const userListingDownload = createAsyncThunk(
  "userListingDownload",
  async (details) => {
    const { data } = await Adminapi.get(`/userManagement`);

    return data;
  }
);

// create sevice categoty

export const createServiceCategory = createAsyncThunk(
  "createServiceCategory",
  async (details) => {
    const { data } = await Adminapi.post("/createServiceCategory", details);
    return data;
  }
);

// GET SERVICE CATEGORY LIST

export const getServiceCategoryList = createAsyncThunk(
  "getServiceCategoryList",
  async (details) => {
    const { data } = await Adminapi.get(
      `/getServiceCategoryList?page=${details?.page}&limit=${details?.limit}&search=${details?.search}&type=${details?.type}`
    );

    return data;
  }
);

// delete SERVICE CATEGORY

export const deleteServiceCategory = createAsyncThunk(
  "deleteServiceCategory",
  async (details) => {
    const { data } = await Adminapi.get(`/deleteServiceCategory?id=${details}`);

    return data;
  }
);

// Create Community
export const CreateCommunit = createAsyncThunk(
  "CreateCommunit",
  async (details) => {
    const data = await Adminapi.post("/createCommunity", details);
    console.log(data);
    return data;
  }
);

// get Community listing

export const getCommunityList = createAsyncThunk(
  "deleteServiceCategory",
  async (details) => {
    const { data } = await Adminapi.get(
      `/getCommunityList?page=${details?.page}&limit=${details?.limit}&search=${details?.search}&type=${details?.type}`
    );

    return data;
  }
);

// DElete Community

export const DeleteCommunity = createAsyncThunk(
  "DeleteCommunity",
  async (details) => {
    console.log(details, "details");
    const data = await Adminapi.delete(`/deleteCommunity/${details?.id}`);

    return data;
  }
);

export const getCommunitybyId = createAsyncThunk(
  "getCommunitybyId",
  async (details) => {
    console.log(details, "details");
    const data = await Adminapi.get(
      `/getCommunityById?communityId=${details?.communityId}`
    );
    return data;
  }
);
// Find community by id for update
export const UpdateCommunity = createAsyncThunk(
  "UpdateCommunity",
  async (details) => {
    console.log(details, "details");
    const data = await Adminapi.put(`/updateCommunity`, details);
    return data;
  }
);

// Dasboard data

export const getdashboard = createAsyncThunk(
  "getdashboard",
  async (details) => {
    console.log(details, "details");
    const data = await Adminapi.get(`/dashboard_count`, details);
    return data;
  }
);

// Order Management Listing

export const getOrders = createAsyncThunk("getOrders", async (details) => {
  const { page, limit, search, type } = details;

  let queryString = `/get_bookings?page=${page}&limit=${limit}&status=${type}`;

  if (search) {
    queryString += `&search=${search}`;
  }

  const { data } = await Adminapi.get(queryString);
  return data;
});

// =============== Add SOS ================

export const AddSOS = createAsyncThunk("AddSOS", async (details) => {
  const data = await Adminapi.post(`/add_sos`, details);
  return data;
});

// ================ SOS Listing ================

export const getAllSOS = createAsyncThunk("getAllSOS", async (details) => {
  const { data } = await Adminapi.get(
    `/list_sos?page=${details?.page}&limit=${details?.limit}&search=${details?.search}`
  );

  return data;
});

// ================= Delete SOS ===============

export const DeleteSos = createAsyncThunk("DeleteSos", async (details) => {
  const data = await Adminapi.delete(`/delete_sos/${details?.sos_id}`);
  return data;
});

// =============== Add FAQ's ================

export const AddFaqs = createAsyncThunk("AddFaqs", async (details) => {
  const data = await Adminapi.post(`/add_faqs`, details);
  return data;
});

// ================ FAQ's Listing ================

export const getAllFaq = createAsyncThunk("getAllFaq", async (details) => {
  const { data } = await Adminapi.get(
    `/list_faqs?page=${details?.page}&limit=${details?.limit}&search=${details?.search}`
  );

  return data;
});

// ================= Delete FAQ's ===============

export const DeleteFaq = createAsyncThunk("DeleteFaq", async (details) => {
  const data = await Adminapi.delete(`/delete_faqs/${details?.faq_id}`);
  return data;
});

// =============== Add Tutorial ================

export const addTutorial = createAsyncThunk("AddTutorial", async (details) => {
  const data = await Adminapi.post(`/add_tutorial`, details);
  return data;
});

// ================ Tutorial Listing ================

export const getAllTutorial = createAsyncThunk(
  "getAllTutorial",
  async (details) => {
    const { data } = await Adminapi.get(
      `/list_tutorial?page=${details?.page}&limit=${details?.limit}&search=${details?.search}`
    );

    return data;
  }
);

// ================= Delete Tutorial ===============

export const DeleteTutorial = createAsyncThunk(
  "DeleteTutorial",
  async (details) => {
    const data = await Adminapi.delete(`/delete_tutorial/${details?.id}`);
    return data;
  }
);

// =============== Add Notification ================

export const addNotification = createAsyncThunk(
  "addNotification",
  async (details) => {
    const data = await Adminapi.post(`/send_admin_notificatons`, details);
    return data;
  }
);

// ================ Notification Listing ================

export const getAllNotifications = createAsyncThunk(
  "getAllNotifications",
  async (details) => {
    const { data } = await Adminapi.get(
      `/list_admin_notificatons?page=${details?.page}&limit=${details?.limit}&search=${details?.search}`
    );
    return data;
  }
);

// ================= Delete Notification ===============

export const DeleteNotification = createAsyncThunk(
  "DeleteNotification",
  async (details) => {
    const data = await Adminapi.delete(`/delete_notifications/${details?.id}`);
    return data;
  }
);

// =========== Get Service Provider Profile Detail By ID ===========

export const getServiceProviderDetails = createAsyncThunk(
  "getServiceProviderDetails",
  async (details) => {
    const data = await Adminapi.get(
      `/getServicesubcategory_data/?id=${details?.id}`
    );

    return data;
  }
);

// =========== Get Orders Detail By ID ===========

export const getOrderDetails = createAsyncThunk(
  "getOrderDetails",
  async (details) => {
    const data = await Adminapi.get(
      `/get_bookingsbyId/?booking_id=${details?.id}`
    );

    return data;
  }
);

// ========= Support List ==========

export const getSupportList = createAsyncThunk(
  "getSupportList",
  async (details) => {
    let url = `/list_support?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }
    if (details.Status) {
      url += `&status=${details.Status}`;
    }
    const data = await Adminapi.get(url);
    console.log(data);
    return data;
  }
);

//  download Support list

export const dowanloadsupportlist = createAsyncThunk(
  "dowanloadsupportlist",
  async () => {
    const { data } = await Adminapi.get(`/list_support`);

    return data;
  }
);

// ========= Paymentmanagement listing ==========

export const Paymentmanagement = createAsyncThunk(
  "Paymentmanagement",
  async (details) => {
    let url = `/list_payment?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }
    // if (details.type) {
    //   url += `&type=${details.type}`;
    // }
    const data = await Adminapi.get(url);
    console.log(data);
    return data;
  }
);

// ========= Withdrawal listing ==========

export const Withdrawalmanagement = createAsyncThunk(
  "Paymentmanagement",
  async (details) => {
    let url = `/withdrawlrequest_payment?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }

    const data = await Adminapi.get(url);
    console.log(data);
    return data;
  }
);

// ============ Support status  ============

export const updatesupportstatus = createAsyncThunk(
  "updatesupportstatus",
  async (details) => {
    const data = await Adminapi.post(`/update_support_status`, details);
    return data;
  }
);

// ============ withdrawal status update  ============

export const UpdateWithdrawalStatus = createAsyncThunk(
  "UpdateWithdrawalStatus",
  async (details) => {
    const data = await Adminapi.post(`/update_withdrawal_status`, details);
    return data;
  }
);

// ========== get service provider data ==========

export const serviceproviderdata = createAsyncThunk(
  "serviceproviderdata",
  async (details) => {
    const data = await Adminapi.get(
      `/getServicesubcategory_data/?id=${details?.id}`
    );

    return data;
  }
);

// service provider Delete Account

export const ServiceproviderDelete = createAsyncThunk(
  "ServiceproviderDelete",
  async (details) => {
    const data = await Adminapi.post("/deleteCustomer", details);
    return data;
  }
);

// Suspend Service provider
export const SuspendServiceprovider = createAsyncThunk(
  "SuspendServiceprovider",
  async (details) => {
    const { data } = await Adminapi.post(
      `/activateDeactivateCustomer`,
      details
    );

    return data;
  }
);

// Get all Services

export const allservices = createAsyncThunk("allservices", async (details) => {
  let url = `/getallservice?page=${details?.page}&limit=${details?.limit}`;

  if (details.search) {
    url += `&search=${details.search}`;
  }

  const data = await Adminapi.get(url);
  console.log(data);
  return data;
});

// ================= Delete Srvices ===============

export const Deleteservice = createAsyncThunk(
  "Deleteservice",
  async (details) => {
    const data = await Adminapi.delete(`/remove_service/${details?.id}`);
    return data;
  }
);

// get Service details

export const getservicedetails = createAsyncThunk(
  "getservicedetails",
  async (details) => {
    const data = await Adminapi.get(
      `/category_withserviceprovider?id=${details?.id}`
    );

    return data;
  }
);

// ========   get account verification detail =========

export const accountVerificationdetails = createAsyncThunk(
  "accountVerificationdetails",
  async (details) => {
    const data = await Adminapi.get(`/getUserDetails/?id=${details?.id}`);

    return data;
  }
);

// ========== status in order details page =============

export const ChageStatusOrderDetails = createAsyncThunk(
  "ChageStatusOrderDetails",
  async (details) => {
    const { data } = await Adminapi.post(
      `/update_booking_status
      `,
      details
    );

    return data;
  }
);

// ========== get Faq by Id =============

export const getFaqByid = createAsyncThunk("getFaqByid", async (details) => {
  const { data } = await Adminapi.get(
    `/get_faq_by_id/${details?.faq_id}
      `
  );

  return data;
});

// =========  Edit faq  =========

export const Editfaq = createAsyncThunk("Editfaq", async (details) => {
  const { data } = await Adminapi.post(
    `/edit_faqs
      `,
    details
  );

  return data;
});

//  verfied badge in service provider

export const verifybadge = createAsyncThunk("verifybadge", async (details) => {
  const { data } = await Adminapi.post(
    `/verified_unverifiedServiceProvider`,
    details
  );

  return data;
});

// Get all Deleted Service Provider

export const getDeletedServiceProvider = createAsyncThunk(
  "getDeletedServiceProvider",
  async (details) => {
    let url = `/getdeleteservice_providers?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }

    const data = await Adminapi.get(url);
    console.log(data);
    return data;
  }
);

//  dowanload list of Deleted Service Providers

export const DowanloadDeleteServProviders = createAsyncThunk(
  "DowanloadDeleteServProviders",
  async (details) => {
    const { data } = await Adminapi.get(`/getdeleteservice_providers`, details);

    return data;
  }
);

//  UnDelete Service Provider Account

export const UndeleteSPAccount = createAsyncThunk(
  "UndeleteSPAccount",
  async (details) => {
    const { data } = await Adminapi.post(`/undelete_SPaccount`, details);
    return data;
  }
);
