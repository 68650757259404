import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";

import Search from "../Components/Layout/Search";
import ShowingResult from "../Components/Layout/ShowingResult";
import Limit from "../Components/Layout/Limit";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import { Button, Modal } from "react-bootstrap";
import {
  Paymentmanagement,
  UpdateWithdrawalStatus,
  Withdrawalmanagement,
  updatesupportstatus,
} from "../Redux/Actions/adminAction";

export default function PaymentManagement() {
  const dispatch = useDispatch();
  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [pageType, setPageType] = useState("");
  const [pagi, setPagi] = useState("");
  console.log(pagi, "");
  const [SosData, setSosList] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [activeToggle, setActiveToggle] = useState("images"); // Initially set 'images' as active


  useEffect(() => {
    const fetchData = async () => {
      try {
        let res;
        if (activeToggle === "images") {
          res = await dispatch(Paymentmanagement({ page, search, limit })); // Fetch payment management data
        } else if (activeToggle === "heicToJpg") {
          res = await dispatch(Withdrawalmanagement({ page, search, limit })); // Fetch withdrawal request data
        }

        if (res?.payload?.data?.success) {
          if (activeToggle === "images") {
            setPaymentData(res.payload.data.data); // Assuming 'items' is the data you want
          } else if (activeToggle === "heicToJpg") {
            setWithdrawalData(res.payload.data.data); // Adjust this based on your API response
          }
          setPagi(res.payload.data.pagination);
        }
      } catch (error) {
        console.log("Error occurred:", error);
      }
    };

    fetchData();
  }, [activeToggle, page, search, limit, flag, dispatch]);

  const handlestatus = (id, status) => {
    dispatch(UpdateWithdrawalStatus({ Withdrawl_id: id, status: status }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.success === true) {
          toast.success(res?.payload?.data?.message);
          setflag(!flag)

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const startIndex = 1 + (page - 1) * limit;
  const endIndex = Math.min(page * limit, pagi?.totalItems);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Payment Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}></Col>
              {/* <Col xxl={3} xl={3} lg={4} md={4}>
              
              </Col> */}
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              ></Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="drop-down">
            <div className="toggle-box-top">
              {/* First toggle (Images) */}
              <div
                className={`toggle-btn ${
                  activeToggle === "images" ? "toggle-active" : ""
                }`}
                onClick={() => setActiveToggle("images")} // Set active state to 'images' on click
              >
                <p> Payment Management</p>
              </div>

              {/* Second toggle (HEIC To JPG) */}
              <div
                className={`toggle-btn ${
                  activeToggle === "heicToJpg" ? "toggle-active" : ""
                }`}
                onClick={() => setActiveToggle("heicToJpg")} // Set active state to 'heicToJpg' on click
              >
                <p>Withdrawal Request</p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {startIndex} - {endIndex} of {pagi?.totalItems} results
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/* =================================================================================================================== */}

      {activeToggle === "images" && (
        <Container fluid className="px-0">
          <div class="table-responsive">
            <Table size="sm" className="table-cmn">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Order ID </th>
                  <th>Service Provider</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {paymentData?.map((res, index) => {
                  const serialNumber = (page - 1) * limit + index + 1;

                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{res?.order_id || "N/A"}</td>
                      <td>{res?.serviceProvider?.full_name || "N/A"}</td>
                      <td>{res?.payment?.amount || "N/A"}</td>
                      <td>
                        <div className="">
                          <Button className="account-btn theme-colr-btn">
                            {res?.payment?.status || "N/A"}
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Container>
      )}

      {activeToggle === "heicToJpg" && (
        <Container fluid className="px-0">
          <div class="table-responsive">
            <Table size="sm" className="table-cmn">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Request Provider Name</th>
                  <th>Email</th>
                  <th>Request Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {withdrawalData?.map((res, index) => {
                  const serialNumber = (page - 1) * limit + index + 1;

                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{res?.serviceProvider?.full_name || "N/A"}</td>
                      <td>{res?.serviceProvider?.email || "N/A"}</td>

                      <td>{res?.amount || "N/A"}</td>
                      <td>
                        {res?.status
                          ? res.status.charAt(0).toUpperCase() +
                            res.status.slice(1)
                          : "N/A"}
                      </td>

                      <td>
                        <div className="select-box">
                          <Form.Select
                            aria-label="Default select example"
                            value={res?.status}
                            disabled={res?.status == "completed" ? true : false}
                            onChange={(e) =>
                              handlestatus(res?._id, e.target.value)
                            }
                          >
                            <option value="requested">Request</option>
                            <option value="completed">Paid</option>
                          </Form.Select>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Container>
      )}

      <Pagination totalstuff={pagi.totalItems / limit} setpages={setpage} />
    </Layout>
  );
}
