import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";
import ShowingResult from "../Components/Layout/ShowingResult";
import Limit from "../Components/Layout/Limit";
import { Deleteservice, allservices } from "../Redux/Actions/adminAction";
import Pagination from "../Components/Layout/Pagination";

export default function AllServices() {
  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [pageType, setPageType] = useState("");
  const [serviceslist, setservicesList] = useState([]);
  const [pagi, setpagi] = useState("");
  const [showdel, setshowdel] = useState(false);
  const [ID, setId] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allservices({ page, search, limit }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.success === true) {
          setservicesList(res?.payload?.data?.data);
          setpagi(res?.payload?.data?.pagination);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [page, search, limit,flag]);




  const DeleteSosHandle = async () => {
    const data = await dispatch(Deleteservice({ id: ID }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setflag(!flag);
      setshowdel(false);
    }
  };

  const startIndex = 1 + (page - 1) * limit;
  const endIndex = Math.min(page * limit, pagi?.totalItems);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>All Services</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/ServiceProviders">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              <div className="cmn-btn">
                <Link to="/ServiceProviders" className="me-3">
                  Overview
                </Link>
                <Link to="/AllServices">All Services</Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {startIndex} - {endIndex} of {pagi?.totalItems} results
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onClick={(e) => setlimit(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Service Category</th>
                <th>Service name</th>
                <th>Decscription</th>
                {/* <th>Profile Image</th> */}
                {/* <th>Position</th> */}
                {/* <th>Location</th>
                <th>Status</th> */}
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {serviceslist?.map((res, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <Link to={`/service-details/${res?.serviceProviderId?._id}`}>
                        <b>
                          {res?.serviceCategoryId?.service_category_name ||
                            "N/A"}
                        </b>
                      </Link>
                    </td>

                    <td>{res?.serviceSubCategoryName || "N/A"}</td>
                    <td>{res?.desc || "N/A"}</td>
                    <td> {res?.price || "N/A"}</td>
                    <td>
                      <div>
                        <Button
                          className="account-btn theme-colr-btn"
                          onClick={() => {
                            setshowdel(true);
                            setId(res?._id);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Remove this Service 
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={DeleteSosHandle}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination totalstuff={pagi.totalItems / limit} setpages={setpage} />
    </Layout>
  );
}
