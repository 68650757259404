import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../Assets/Images/placeholder 1 .png";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { toast } from "react-toastify";
import {
  UpdateCommunity,
  getCommunitybyId,
} from "../Redux/Actions/adminAction";
import { formToJSON } from "axios";

export default function EventView() {
  const [FindData, setFindData] = useState("");

  const [EvenetDate, seteventDate] = useState("");
  const [eventName, seteventName] = useState("");
  const [evenetAddress, seteventAddress] = useState("");
  console.log(evenetAddress,"evenetAddressss");
  const [photos, setphotos] = useState([]);
  const [bookinglink, setbookingLink] = useState("");
  const [Description, setdescription] = useState("");
  const [locationLink, setlocationLink] = useState("");
  const [image, setimages] = useState([]);


  // for errors
  const [nameErr, setNameErr] = useState("");
  const [dateErr, setDateErr] = useState("");
  const [descErr, SetDescErr] = useState("");
  const [bookinglinkErr, setbookinglinkErr] = useState("");
  const [locationLinkErr, setlocationLinkErr] = useState("");
  const [eventAddressErr, seteventAddressErr] = useState("");
  const [photosErr, setphotosErr] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");




  const handlePlaceSelect = (place) => {
    console.log("place", place);
    if (!place) {
      seteventAddress(null);
      return;
    }

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ placeId: place?.value?.place_id }, (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0]?.geometry?.location;
        const newLocation = {
          lat: location?.lat(),
          lng: location?.lng(),
        };
        seteventAddress({
          label: place.label,
          lat: newLocation?.lat,
          lng: newLocation?.lng,
          value: {
            description: place.value.description,
            place_id: place.value.place_id,
          },
        });
      } else {
        console.error(
          "Geocode was not successful for the following reason: " + status
        );
        toast.error("Failed to fetch coordinates");
      }
    });
  };

  var getdata = async () => {
    let data = await dispatch(
      getCommunitybyId({ communityId: location?.state?.id })
    );
    console.log(data);
    if (data?.payload?.status === 200) {
      let x = data?.payload?.data?.data?.eventDate?.split("T")[0];
      seteventName(data?.payload?.data?.data?.eventName);
      seteventDate(x);
      // seteventAddress(data?.payload?.data?.data?.eventAddress);
      seteventAddress({
        label: data?.payload?.data?.data?.eventAddress,
        lat: data?.payload?.data?.data?.location?.coordinates[1] ,
        lng: data?.payload?.data?.data?.location?.coordinates[0],
        value: {
          description: data?.payload?.data?.data?.eventAddress,
          place_id: data?.payload?.data?.data?.place_id,
        },
      })
      setdescription(data?.payload?.data?.data?.description);
      setbookingLink(data?.payload?.data?.data?.bookingLink);
      setlocationLink(data?.payload?.data?.data?.locationLink);
      setphotos(data?.payload?.data?.data?.photos);

      console.log(data?.payload?.data?.data, "datedate");
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const handleImageChange = (e) => {
    const files = e.target.files;
    console.log(files);
    if (files.length > 2) {
      toast.error("You can upload a maximum of 2 images.");
      return;
    }
    Array.from(files);
    setimages(Array.from(files).map((file) => file));
    setphotosErr("");
  };



  const handlesave = async () => {
    let isValid = true;

    if (!eventName) {
      setNameErr("Required");
      isValid = false;
    } else {
      setNameErr("");
    }

    if (!evenetAddress) {
      seteventAddressErr("Required");
      isValid = false;
    } else {
      seteventAddressErr("");
    }

    if (!EvenetDate) {
      setDateErr("Required");
      isValid = false;
    } else {
      setDateErr("");
    }

    if (!Description) {
      SetDescErr("Required");
      isValid = false;
    } else {
      SetDescErr("");
    }

    if (!locationLink) {
      setlocationLinkErr("Required");
      isValid = false;
    } else {
      setlocationLinkErr("");
    }

    if (!bookinglink) {
      setbookinglinkErr("Required");
      isValid = false;
    } else {
      setbookinglinkErr("");
    }

    if (isValid) {
      const formdata = new FormData();

      formdata.append("description", Description);
      formdata.append("eventName", eventName);
      formdata.append("eventDate", EvenetDate);
      formdata.append("eventAddress", evenetAddress?.label);
      formdata.append("bookingLink", bookinglink);
      formdata.append("locationLink", locationLink);
      formdata.append("communityId", location?.state?.id);
      formdata.append("lat", evenetAddress?.lat);
      formdata.append("long", evenetAddress?.lng);

      image.forEach((img, index) => {
        formdata.append(`photos`, img);
      });

      try {
        let data = await dispatch(UpdateCommunity(formdata)).then((res) => {
          if (res?.payload?.status == 200) {
            toast.success(res?.payload?.data?.message);
            navigate("/CommunityManagement");
          } else {
            toast.error(res?.res?.message);
          }
        });
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleDateChange = (e) => {
    seteventDate(e.target.value);
    // setDateErr('');
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Event view</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/CommunityManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main mb-4">
                  <Row>
                    <Col lg={12}>
                      <div className=" px-3 py-4 h-100">
                        <div className="property-main mt-0">
                          <h2 className="property">Event Details</h2>
                          <div className="customer-form-new border-none">
                            <Row>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Event name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Event Name"
                                    onChange={(e) => {
                                      seteventName(e.target.value);
                                      setNameErr("");
                                    }}
                                    value={eventName}
                                  />
                                  {nameErr && (
                                    <Form.Text className="text-danger">
                                      {nameErr}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Address</Form.Label>
                                  <GooglePlacesAutocomplete
                                    selectProps={{
                                      value: evenetAddress,
                                      onChange: (place) =>
                                        handlePlaceSelect(place),
                                      isClearable: true,
                                      placeholder:
                                        "Search for a location...",
                                    }}
                                  
                                  />
                                  {eventAddressErr && (
                                    <Form.Text className="text-danger">
                                      {eventAddressErr}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Date</Form.Label>
                                  <Form.Control
                                    type="date"
                                    placeholder="Date"
                                    onChange={handleDateChange}
                                    value={EvenetDate}
                                  />
                                  {dateErr && (
                                    <Form.Text className="text-danger">
                                      {dateErr}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>

                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="DEscription"
                                    onChange={(e) => {
                                      setdescription(e.target.value);
                                      SetDescErr("");
                                    }}
                                    value={Description}
                                  />
                                  {descErr && (
                                    <Form.Text className="text-danger">
                                      {descErr}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Location link</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Location Link"
                                    onChange={(e) => {
                                      setlocationLink(e.target.value);
                                      setlocationLinkErr("");
                                    }}
                                    value={locationLink}
                                  />
                                  {locationLinkErr && (
                                    <Form.Text className="text-danger">
                                      {locationLinkErr}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Booking link</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Booking Link"
                                    onChange={(e) => {
                                      setbookingLink(e.target.value);
                                      setbookinglinkErr("");
                                    }}
                                    value={bookinglink}
                                  />
                                  {bookinglinkErr && (
                                    <Form.Text className="text-danger">
                                      {bookinglinkErr}
                                    </Form.Text>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col
                                lg={12}
                                className="d-flex justify-content-end mt-3"
                              >
                                <button
                                  type="button"
                                  className="add-btn"
                                  onClick={handlesave}
                                >
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-2">Photos</h2>
                        <div className="images-gallery">
                          <Row>
                            <Col lg={6}>
                              {/* <div className="image-box-item">
                                <img
                                  src={require("../Assets/Images/profile-img.png")}
                                />
                              </div> */}
                              {image?.length === 0 ? (
                                photos?.map((image, index) => (
                                  <div className="main-img-contain">
                                    <div
                                      key={index}
                                      className="image-container"
                                    >
                                      <img
                                        src={
                                          image.startsWith("blob:")
                                            ? image
                                            : `${process.env.REACT_APP_FILE_BASE_URL}public/service/${image}`
                                        }
                                        alt={`Preview ${index}`}
                                      />
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <>
                                  {image?.map((img, index) => (
                                    <div className="main-img-contain">
                                      <div
                                        key={index}
                                        className="image-container"
                                      >
                                        <img src={URL.createObjectURL(img)} />
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}

                              <>
                              <br/>
                                <input
                                  className="user_detail_upload"
                                  id="formFileSm"
                                  type="file"
                                  name="productimage"
                                  onChange={handleImageChange}
                                  accept="image/*"
                                  multiple
                                />
                              </>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
