import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";
import {
  getServiceProviderDetails,
  getservicedetails,
} from "../Redux/Actions/adminAction";
import { Formik, Field } from "formik";

export default function ServiceDetails() {
  const [servdetails, setServDetails] = useState({});
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getservicedetails({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.success === true) {
          setServDetails(res?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Service details </h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <div className="all-services">
              <div className="product-tab-left">
                <Link to="/AllServices">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
            </div>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main mb-4">
                  <Row>
                    <Col lg={12} xl={12} xxl={12}>
                      <Figure className="d-flex user-details-top">
                        <span>
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              servdetails?.serviceProviderId?.profile_image
                                ? `https://simba-api.bosselt.com/public${servdetails?.serviceProviderId?.profile_image}`
                                : logo
                            }
                          />
                        </span>
                        <div className="detail-user">
                          <h3>
                            {servdetails?.serviceProviderId?.full_name || "N/A"}
                          </h3>
                          <p>
                            {servdetails?.serviceProviderId?.email || "N/A"}
                          </p>
                          <p>
                            {servdetails?.serviceProviderId?.country_code &&
                            servdetails?.serviceProviderId?.phone_number
                              ? `${servdetails?.serviceProviderId?.country_code} ${servdetails?.serviceProviderId?.phone_number}`
                              : "N/A"}
                          </p>
                        </div>
                      </Figure>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Service info</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Service Category</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={
                                    servdetails?.serviceCategoryId
                                      ?.service_category_name || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Service name</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={
                                    servdetails?.serviceSubCategoryName || "N/A"
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={5}
                                  name="description"
                                  value={servdetails?.desc || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Price</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={servdetails?.price || "N/A"}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="user-profile-main mt-4 mb-5 px-3 py-4 ">
                      <div className="property-main mt-0">
                        <h2 className="property">Time slots</h2>
                        <div className="customer-form-new border-none">
                          <Row>
                            {servdetails?.availability?.map((res, index) => {
                              return (
                                <div className="set-time-slot">
                               
                                    <div>
                                      {res &&
                                        res?.day.length > 0 &&
                                        res?.day.map((ele, index) => {
                                          return (
                                            <div key={index}>
                                              <div className="time-slots">
                                              <div className="days-name">
                                                <p>{ele ? ele : "N/A"}</p>
                                              </div>

                                              <div className="new-changes">
                                      {res &&
                                        res?.timeArr.length > 0 &&
                                        res?.timeArr.map((ele, index) => {
                                          return (
                                            <div key={index} className="timer-text">
                                              <>
                                             
                                                          <div
                                                            className="time-picker-ps"
                                                            key={index}
                                                          >
                                                            <input
                                                              type="text"
                                                              id="time"
                                                              value={ele}
                                                              name="time"
                                                            />
                                                          </div>
                                                   
                                              </>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  
                                   
                                              </div>
                                            </div>
                                          );
                                        })}
                                   </div>

                                   
                               
                                </div>
                              );
                            })}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Photos</h2>
                        <div className="active-default-links">
                          <div className="documents-box">
                            <div className="govt-doc">
                              <div className="enter-box">
                                <>
                                  {servdetails?.images &&
                                  servdetails?.images.length > 0 ? (
                                    <>
                                      {servdetails?.images?.map((res) => {
                                        return (
                                          <>
                                            <img
                                              src={`${url}public/user/${res}`}
                                            />
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <>Images Not Found</>
                                  )}
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
