import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DelecteAccount,
  changeStatus,
  getCustomerProfile,
  getUserProfile,
} from "../Redux/Actions/adminAction";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";

export default function CustomerProfile() {
  const [show, setshow] = useState(false);
  const [showdel, setshowdel] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermgmtData.customerDetails);
  console.log(data, "userDATA");
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCustomerProfile(id));
  }, [id]);

  const handledelclick = async () => {
    setshowdel(true);
  };

  const handleclick = async () => {
    setshow(true);
  };

  const countryCode = data?.message?.country_code;
  const phoneNumber = data?.message?.phone_number;

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>User Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/CustomerManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main mb-4">
                  <Row>
                    <Col lg={12} xl={12} xxl={12}>
                      <div className="d-flex figure">
                        <span>
                          <img
                            alt="171x180"
                            src={
                              data?.data?.user?.profile_image
                                ? `https://simba-api.bosselt.com/public${data?.data?.user?.profile_image}`
                                : logo
                            }
                          />
                        </span>
                        <Figure.Caption>
                          <div className="property-main mt-0">
                            <h2 className="property">Profile info</h2>
                            <div className="customer-form-new border-none">
                              <Row>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Customer name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Name"
                                      value={
                                        data?.data?.user?.full_name
                                          ? data?.data?.user?.full_name
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                      type="email"
                                      placeholder="Email"
                                      value={data?.data?.user?.email}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Phone number</Form.Label>
                                    <Form.Control
                                      type="test"
                                      placeholder="Phone"
                                      value={
                                        data?.data?.user?.country_code &&
                                        data?.data?.user?.phone_number
                                          ? `${data?.data?.user?.country_code} ${data?.data?.user?.phone_number}`
                                          : data?.data?.user?.phone_number
                                          ? data?.data?.user?.phone_number
                                          : "N/A"
                                          
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                {/* <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Address"
                                      value={
                                        data?.data?.user?.address
                                          ? data?.data?.user?.address
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col> */}
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>House Number</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="House Number"
                                      value={data?.data?.user?.house_number || "N/A"}

                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Street Address</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Address"
                                      value={data?.data?.user?.address || "N/A"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Suburbs</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Suburbs"
                                      value={data?.data?.user?.suburbs || "N/A"}

                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Country"
                                      value={data?.data?.user?.country || "N/A"}

                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Post Code or PO Box</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Post Code"
                                      value={data?.data?.user?.post_code || "N/A"}

                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Landmark</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Landmark"
                                      value={data?.data?.user?.landMark}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col
                                  lg={12}
                                  className="d-flex justify-content-end mt-3"
                                >
                                  {/* <button type="button" className="add-btn">
                                  Save
                                </button> */}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Figure.Caption>
                      </div>
                    </Col>
                    {/* <Col lg={8} xl={9} xxl={10}>
                      <div className=" px-3 py-4 h-100"></div>
                    </Col> */}
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12} className="">
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Activity</h2>
                        <div className="active-default-links">
                          {data?.data?.activities?.map((res) => {
                            return (
                              <ul>
                                <li>
                                  <h6>{res?.title}</h6>
                                  <p>
                                    {res?.createdAt
                                      ? new Date(res?.createdAt)
                                          .toISOString()
                                          .slice(0, 10)
                                          .replace(/-/g, "/")
                                      : "N/A"}
                                  </p>
                                </li>
                              </ul>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Delete Account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            Once you delete your account, you can not retrieve
                            the account. Please be certain.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={handledelclick}
                          >
                            Delete Account
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Suspend account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            You will not be able to receive messages,
                            notifications for up to 24hours.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={handleclick}
                          >
                            Suspend Account
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to suspend this account ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(changeStatus({ id: id, status: 0 })).then((res) => {
                if (res?.payload?.success) {
                  toast.success(res?.payload?.message);
                  setshow(false);
                  navigate("/CustomerManagement");
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to delete this account ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(DelecteAccount({ _id: id })).then((res) => {
                console.log(res, "response");
                if (res?.payload?.status === 200) {
                  toast.success(res?.payload?.data?.message);
                  setshowdel(false);
                  navigate("/CustomerManagement");
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
