import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { DeleteFaq, getAllFaq } from "../Redux/Actions/adminAction";
import Pagination from "../Components/Layout/Pagination";

export default function Faq() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [pageType, setPageType] = useState("");
  const [pagi, setPagi] = useState("");
  const [FaqsData, setFaqList] = useState([]);
  const [showdel, setshowdel] = useState(false);
  const [ID, setId] = useState("");

  useEffect(() => {
    dispatch(getAllFaq({ page, search, limit }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.success === true) {
          setFaqList(res?.payload?.data);
          setPagi(res?.payload?.pagination);
        }
      })
      .catch((err) => {
        console.log(err, "Error Occur");
      });
  }, [page, search, limit, flag]);

  const DeleteFaqHandle = async () => {
    const data = await dispatch(DeleteFaq({ faq_id: ID }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setflag(!flag);
      setshowdel(false);
    }
  };

  const handlenavigate = (id) => {
    navigate(`/edit-faq/${id}`);
  };

  const startIndex = 1 + (page - 1) * limit;
  const endIndex = Math.min(page * limit, pagi?.totalItems);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>FAQ’S </h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/AddFaq">ADD FAQ</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  All
                </Link>
              </li>
              {/* <li>
                <Link to="#">Open  3,458</Link>
              </li>
              <li>
                <Link to="#">Closed  9,876</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {startIndex} - {endIndex} of {pagi?.totalItems} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Question</th>
                <th>Answer</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {FaqsData?.map((res, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{res?.question || "N/A"}</td>
                    <td dangerouslySetInnerHTML={{ __html: res?.answer }}></td>
                    {/* <td>
                      <div className="">
                        <Button
                          className="account-btn theme-colr-btn"
                          onClick={() => {
                            setshowdel(true);
                            setId(res?._id);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                      <div className="">
                        <Button
                          className="account-btn theme-colr-btn" onClick={() => handlenavigate(res?._id)}
                        >
                          Edit
                        </Button>
                      </div>
                      
                    </td> */}

                    <td>
                      <div className="action-edit-delt">
                        <div onClick={() => handlenavigate(res?._id)}>
                          <img
                            alt="photo"
                            src={
                              require("../Assets/Images/edit_icon.svg").default
                            }
                          />
                        </div>
                        <img
                          src={
                            require("../Assets/Images/delete_icon.png")
                          }
                          onClick={() => {
                            setshowdel(true);
                            setId(res?._id);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to remove this FAQ ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={DeleteFaqHandle}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination totalstuff={pagi.totalItems / limit} setpages={setpage} />
    </Layout>
  );
}
