import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AddFaqs, Editfaq, getFaqByid } from "../Redux/Actions/adminAction";

const validationSchema = Yup.object().shape({
  type: Yup.string().required("Required"),
  question: Yup.string()
    .trim()
    .required("Required")
    .min(5, "Question must be at least 5 characters")
    .max(100, "Question must not exceed 100 characters"),
  answer: Yup.string()
    .trim()
    .required("Required")
    .min(5, "Answer must be at least 5 characters")
    .max(1000, "Answer must not exceed 1000 characters"),
});

export default function AddFaq() {
  const [FaqData, setFaqdata] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getFaqByid({ faq_id: id })).then((res) => {
        console.log(res);
        if (res?.payload?.success === true) {
          setFaqdata(res?.payload?.data);
        }
      });
    }
  }, [id, dispatch]);

  const initialvalues = {
    question: "",
    answer: "",
    type: "",
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>{id ? "Edit FAQ" : "Add FAQ"}</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Faq">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
          <Formik
            initialValues={FaqData || initialvalues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              console.log(values);
              try {
                if (id) {
                  let payload = {
                    question: values?.question,
                    answer: values?.answer,
                    type: values?.type,
                    faqs_id: id,
                  };
                  const data = await dispatch(Editfaq(payload));
                  console.log(data);
                  if (data?.payload?.success  === true) {
                    toast.success(data?.payload?.message);
                    navigate("/Faq");
                  } else {
                    toast.error(data?.payload?.data?.message);
                  }
                } else {
                  const data = await dispatch(AddFaqs(values));
                  console.log(data);
                  if (data?.payload?.data?.success) {
                    toast.success(data?.payload?.data?.message);
                    navigate("/Faq");
                  } else {        
                    toast.error(data?.payload?.data?.message);
                  }
                }
              } catch (error) {
                console.log(error, "errorrrr");
              }
            }}
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => (
              <Col lg={6}>
                <Form onSubmit={handleSubmit}>
                  <div className="customer-form-new">
                    <div className="customer-form-inner">
                      <Row>
                        <Col lg={12}>
                          <Form.Group>
                            <Form.Label>Select Type</Form.Label>

                            <div>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                className="mb-3 w-100"
                                name="type"
                                value={values?.type}
                                onChange={handleChange}
                              >
                                <option>Select</option>

                                <option value="3">All</option>
                                <option value="1">Service Provider</option>
                                <option value="2">Customer</option>
                              </select>
                            </div>
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Add Question</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Text here..."
                              name="question"
                              value={values.question}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="question"
                              component="div"
                              className="error"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Add Answer</Form.Label>
                            <ReactQuill
                              theme="snow"
                              value={values.answer}
                              onChange={(value) =>
                                setFieldValue("answer", value)
                              }
                            />
                            <ErrorMessage
                              name="answer"
                              component="div"
                              className="error"
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          lg={12}
                          className="d-flex justify-content-center mt-3"
                        >
                          <button type="submit" className="add-btn">
                            {id ? "Update" : "Create"}
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </Col>
            )}
          </Formik>
        </Row>
      </Container>
    </Layout>
  );
}
