import React, { useCallback, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  accountVerification,
  changeStatus,
  changeVerification,
  downloadaccountVerification,
  userListing,
  userListingDownload,
} from "../Redux/Actions/adminAction";
import Search from "../Components/Layout/Search";
import ShowingResult from "../Components/Layout/ShowingResult";
import Limit from "../Components/Layout/Limit";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import { Button, Modal } from "react-bootstrap";

export default function AccountVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermgmtData.unverifiedUsers);
  console.log(data, "userList");

  const [page, setpage] = useState("1");
  const [show, setshow] = useState(false);
  const [name, setName] = useState(null);
  const [id, setId] = useState(null);
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [pageType, setPageType] = useState("");

  const [reason, setReason] = useState("");
  const [ResonErr, setresonErr] = useState("");

  const handleSubmit = () => {
    if (name === "reject") {
      if (!reason.trim()) {
        setresonErr("Reason is required.");
        return;
      }

      dispatch(
        changeVerification({
          id: id,
          status: name === "accept" ? "1" : "0",
          reason: reason,
        })
      ).then((res) => {
        if (res?.payload?.success) {
          toast.success(res?.payload?.message);
          setshow(false);
          setflag(!flag);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } else {
      setReason("");
      dispatch(
        changeVerification({
          id: id,
          status: name === "accept" ? "1" : "0",
          reason: reason,
        })
      ).then((res) => {
        if (res?.payload?.success) {
          toast.success(res?.payload?.message);
          setshow(false);
          setflag(!flag);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  const handlechange = (e) => {
    setlimit(e.target.value);
  };

  useEffect(() => {
    dispatch(accountVerification({ page, search, limit, type: "" }));
  }, [page, search, limit, flag]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(downloadaccountVerification());
      console.log(response);

      const allData = response?.payload?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Account Verification</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  {/* <Form.Control type="search" placeholder="Keyword Search.." /> */}
                  <Search setsearch={setsearch} />
                </Form.Group>
              </Col>
              {/* <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Profile Action</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select>
              </Col> */}
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/CustomerManagement" className="active-tab">
                  All
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                {/* <ShowingResult page={page} limit={limit} /> */}
                <div className="show-results">
                  {" "}
                  Showing {1 + (page - 1) * limit} -{" "}
                  {data?.data?.users?.length + (page - 1) * limit} of{" "}
                  {data?.data?.totalUsers} results
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={handlechange}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Profile Name </th>
                <th>Company Name </th>
                <th>Phone Number </th>
                <th>Email</th>
                <th>Profile Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.users?.map((user, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/user-profile/${user?._id}`)}
                        className="owner-name"
                      >
                        {user?.full_name || "N/A"}
                      </div>
                    </td>
                    <td>{user?.company_name || "N/A"}</td>

                    <td>
                      {user?.country_code} {user?.phone_number || "N/A"}
                    </td>
                    <td>{user?.email || "N/A"}</td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                        className="cmn-btn "
                      >
                        <Button
                          onClick={() => {
                            setName("accept");
                            setshow(true);
                            setId(user?._id);
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          onClick={() => {
                            setName("reject");
                            setshow(true);
                            setId(user?._id);
                          }}
                        >
                          Reject
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.data?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Modal show={show} className="delete-popup delete-form-new">
        <Modal.Body className="heading-top new-heading-top">
          {name === "reject" ? (
            <>
              <h2 className="are-sure-text">Reason</h2>
              <div>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Type here"
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                      if (ResonErr) setresonErr("");
                    }}
                  />
                  {ResonErr && (
                    <p className="error-text" style={{ color: "red" }}>
                      {ResonErr}
                    </p>
                  )}
                </Form.Group>
              </div>
            </>
          ) : (
            <h1 style={{ display: "flex", justifyContent: "center" }}>
              Are You sure you want to Accept this Request?
            </h1>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none cmn-btn">
          <Button className="theme-btn" onClick={handleSubmit}>
            Submit
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination
        totalstuff={data?.data?.totalPage}
        limit={data?.data?.limit}
        setpages={setpage}
        search={search}
      />
    </Layout>
  );
}
